const burgerButton = document.querySelector(".navbar__burger-button");
const navList = document.querySelector(".navbar__list");
const navListItems = document.querySelectorAll(".navbar__list-item");
const languageBox = document.querySelector(".navbar__language-box");
const offerCarousel = document.querySelectorAll(".offer__carousel");
const galleryBox = document.querySelectorAll(".gallery-box");
const galleryImages = document.querySelectorAll(".gallery__image");
const galleryFullview = document.querySelector(".gallery__fullview");
const galleryFullviewImage = document.querySelector(".gallery__fullview-image");
const galleryButton = document.querySelectorAll(".gallery__button");
const galleryCloseButton = document.querySelector(".gallery__button-close");

const carouselGalleryItems = document.querySelectorAll(
	".carousel-gallery-item"
);

const contactFormButton = document.querySelector(".contact__form-button");
const contactFormNameInput = document.querySelector(
	".contact__form-input--name"
);
const contactFormEmailInput = document.querySelector(
	".contact__form-input--email"
);
const contactFormPhoneInput = document.querySelector(
	".contact__form-input--phone"
);
const contactFormMessageInput = document.querySelector(
	".contact__form-textarea"
);

const contactModal = document.querySelector('.contact__modal');
const contactModalText = document.querySelector('.contact__modal-text');
const contactModalButton = document.querySelector(".contact__modal-button");

const toggleGalleryButtons = state => {
	if (!state) {
		galleryButton.forEach(button => {
			button.classList.remove("gallery__button--active");
		});
	} else {
		galleryButton.forEach(button => {
			button.classList.add("gallery__button--active");
		});
	}
};

const handleGalleryButton = e => {
	let newItem;
	if (e.target.dataset.type === "right") {
		newItem =
			galleryImages[parseInt(galleryFullviewImage.dataset.currentimageid)];

		if (newItem == null) {
			newItem = galleryImages[0];
		}
	} else {
		newItem =
			galleryImages[parseInt(galleryFullviewImage.dataset.currentimageid) - 2];
		if (newItem == null) {
			newItem = galleryImages[galleryImages.length - 1];
		}
	}
	galleryFullviewImage.src = newItem.dataset.imgbig;
	galleryFullviewImage.dataset.currentimageid =
		newItem.parentElement.dataset.imageid;
};
const toggleMobileNavigation = () => {
	burgerButton.classList.toggle("navbar__burger-button--active");
	document.body.classList.toggle("fixed-body");
	navList.classList.toggle("navbar__list--active");
	languageBox.classList.toggle("navbar__language-box--active");
};

const toggleGalleryFullview = () => {
	galleryFullview.classList.toggle("gallery__fullview--active");
};

const toggleGalleryImage = event => {
	const source = event.target.children[0].dataset.imgbig;
	const id = event.target.dataset.imageid;

	galleryFullviewImage.src = source;
	galleryFullviewImage.dataset.currentimageid = id;

	if (event.target.children[0].classList.contains("carousel-gallery-item")) {
		toggleGalleryButtons(false);
	} else {
		toggleGalleryButtons(true);
	}

	galleryFullview.classList.toggle("gallery__fullview--active");
};

const validateContactForm = input => input.value.trim() != ""

const handleFormSubmit = e => {
	e.preventDefault();
    const array = [contactFormMessageInput, contactFormNameInput, contactFormPhoneInput, contactFormEmailInput];
	if (
		array.every(validateContactForm)
	) {
		const emailData = {
			personName: contactFormNameInput.value,
			personPhoneNumber: contactFormPhoneInput.value,
			personEmail: contactFormEmailInput.value,
			personMessage: contactFormMessageInput.value,
		};
        toggleFormModal("Wysyłanie...");

		emailjs.send("default_service", "template_xyt2ade", emailData).then(() => {
            if(contactModal.dataset.lang != 'en') {
                contactModalText.textContent = "Wiadomość została wysłana.\n Odezwiemy się możliwie jak najszybciej!";
            } else {
                contactModalText.textContent = "Message has been sent.\n We will write back to you as fast as we can!";
            }
		});
	} else {
        if(contactModal.dataset.lang != 'en') {

            toggleFormModal("Wypełnij puste pola w celu wysłania wiadomości.");
        } else {
            toggleFormModal("Please fill the empty blanks to send a message.");
            
        }
	}
};

const toggleFormModal = (message = "") => {
    contactModal.classList.toggle("contact__modal--active");
    contactModalText.textContent = message;
};

const initLibrary = src => {
	const head = document.head;
	const scriptTag = document.createElement("script");
	scriptTag.type = "text/javascript";
	scriptTag.src = src;
	head.append(scriptTag);
};

const initLibraries = () => {
	initLibrary("https://cdn.jsdelivr.net/npm/emailjs-com@3/dist/email.min.js");

	setTimeout(() => {
		emailjs.init("Bo7hrccaGwwN3K2BO");
	}, 2000);
	contactFormNameInput.removeEventListener("focus", initLibraries);
};
document.addEventListener("DOMContentLoaded", () => {
	burgerButton.addEventListener("click", toggleMobileNavigation);

	offerCarousel.forEach(carousel => {
		let flickity = new Flickity(carousel, {
			cellAlign: "center",
			contain: true,
			prevNextButtons: false,
			pageDots: false,
			lazyLoad: 1,
			autoPlay: 5000,
			pauseAutoPlayOnHover: false,
			wrapAround: true,
		});
		flickity.on("pointerUp", function (event, pointer) {
			flickity.player.play();
		});
	});
	galleryBox.forEach(box => {
		box.addEventListener("click", toggleGalleryImage);
	});
	galleryButton.forEach(button => {
		button.addEventListener("click", handleGalleryButton);
	});
	galleryCloseButton.addEventListener("click", toggleGalleryFullview);
	contactFormButton.addEventListener("click", handleFormSubmit);
	contactFormNameInput.addEventListener("focus", initLibraries);
    contactModalButton.addEventListener('click', toggleFormModal);
	if(window.innerWidth < 1024) {
		navListItems.forEach(item => {
			item.addEventListener('click', toggleMobileNavigation);
		})
	}
});
